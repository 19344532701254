/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import shevy from "../styleguide/typography";
import { H2 } from "../styleguide/heading";
import Paragraph from "../styleguide/paragraph";

import DefaultLayout from "../layouts/default";
import SEO from "../components/seo";
import Wrapper from "../components/wrapper";
import InternalLink from "../components/internal-link";

const NotFoundPage = ({ path }) => {
    return (
        <DefaultLayout>
            <SEO title="404 - Page Not Found" path={path} />
            <Wrapper
                css={css`
                    background: rgba(255, 255, 255, 0.6);
                    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.06);
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    @media (max-width: 550px) {
                        padding: ${shevy.baseSpacing(5)} ${shevy.baseSpacing(1)}
                            ${shevy.baseSpacing(2)};
                    }

                    @media (min-width: 550px) {
                        padding: ${shevy.baseSpacing(5)} ${shevy.baseSpacing(2)}
                            ${shevy.baseSpacing(2)};
                    }
                `}
            >
                <H2>Page Not Found ¯\_(ツ)_/¯</H2>
                <Paragraph>
                    Sorry! This page does not exist. But, if it should exist,
                    please{" "}
                    <InternalLink to="/hello/">let me know about</InternalLink>.
                </Paragraph>
                <Paragraph>
                    <InternalLink to="/">&larr; Back Home</InternalLink>
                </Paragraph>
            </Wrapper>
        </DefaultLayout>
    );
};

export default NotFoundPage;
